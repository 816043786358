html {
  touch-action: manipulation;
}

body {
  color: #fff;
  background: #000;
}

nav#header {
  background: #0001;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem 2rem;
  display: flex;
  box-shadow: 0 0 6rem -1rem #0004;
}

nav#header a.logo {
  z-index: 3;
  position: absolute;
  top: 0;
  right: 30px;
}

nav#header img {
  width: 160px;
  display: block;
}

nav#header a:not(.logo) {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  border-bottom: 3px solid #0000;
  font-family: sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
}

nav#header a:not(.logo):hover, nav#header a:not(.logo).header--active {
  color: #ba181d;
  border-bottom: 3px solid #ba181d;
  position: relative;
}

@media (max-width: 855px) {
  nav#header {
    row-gap: 1rem;
  }

  nav#header a:not(.logo) {
    font-size: 1.2rem;
  }
}

@media (max-width: 738px) {
  nav#header {
    flex-direction: column;
    align-items: flex-start;
  }

  nav#header a.logo {
    position: absolute;
    top: 0;
    right: 0;
  }

  nav#header a.logo img {
    width: 120px;
  }
}

.background-image {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
  object-position: top;
  filter: saturate(.7) blur(3px);
  opacity: .5;
  position: fixed;
}

main {
  max-width: 790px;
  margin: auto;
  padding: 20px;
}

h1, h2, h3, h4, h5, h6 {
  text-shadow: 1px 1px 1px #000;
  font-family: sans-serif;
  font-weight: 700;
}

main.fechas {
  flex-direction: column;
  row-gap: 1rem;
  display: flex;
}

main.fechas article {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

main.fechas h2 {
  width: 100%;
  margin: 0;
}

main.fechas svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

main.fechas time {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

main.fechas h3 {
  width: 100%;
  margin: 0;
  font-weight: lighter;
}

p {
  text-shadow: .5px .5px .5px #000;
  font-family: sans-serif;
  font-weight: 400;
}

a {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-family: sans-serif;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

ul.links {
  flex-direction: column;
  row-gap: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

ul.links li {
  margin: 0;
  padding: 0;
}

ul.links li a {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  align-items: center;
  column-gap: 1rem;
  font-family: sans-serif;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

ul.links li a:hover {
  color: #fff;
  text-decoration: underline;
}

#logoFeteas {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#logoFeteas img {
  width: 80px;
}

@media (max-width: 425px) {
  #logoFeteas {
    bottom: 170px;
    left: 10px;
    right: auto;
  }
}

#logo_escena_amateur {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 100px;
}

#logo_escena_amateur img {
  width: 80px;
}

@media (max-width: 425px) {
  #logo_escena_amateur {
    bottom: 120px;
    left: 10px;
    right: auto;
  }
}

.cartelera {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin: 20px 0;
  display: grid;
}

.cartel {
  max-width: 390px;
  display: block;
  position: relative;
}

.cartel img {
  width: 100%;
}

#masonry {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: center;
  align-items: center;
  gap: .5rem 1rem;
  margin: 100px 1rem;
  display: grid;
  overflow: hidden;
}

#masonry img {
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 1rem .2rem #000;
}

#masonry img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 2rem .4rem #000;
}

.lista, article {
  background-color: #0007;
  flex-direction: column;
  row-gap: 1rem;
  padding: 20px 50px;
  list-style: none;
  display: flex;
}

.lista strong, article strong {
  text-shadow: .5px .5px .5px #000;
  font-family: sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
}

footer {
  column-gap: 2rem;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 200px;
}

footer a {
  align-items: center;
  display: flex;
}

footer div {
  flex-grow: 1;
  justify-content: flex-end;
  column-gap: 1rem;
  display: flex;
}

@media (max-width: 738px) {
  footer {
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: .5rem;
  }

  footer div {
    margin-bottom: .5rem;
  }
}

@media (max-width: 425px) {
  footer {
    left: 10px;
    right: 10px;
  }
}

/*# sourceMappingURL=index.cb47eee5.css.map */
