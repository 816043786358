html {
    touch-action: manipulation;
}

body {
    background: #000;
    color: #fff;
}

nav#header {
    padding: 1rem 2rem;

    a.logo {
        position: absolute;
        right: 30;
        top: 0;
        z-index: 3;
    }

    background: #0001;
    display: flex;
    column-gap: 1rem;
    align-items: center;

    img {
        display: block;
        width: 160px;
    }

    a:not(.logo) {
        color: #fff;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 700;
        text-shadow: 1px 1px 1px #000;
        font-family: sans-serif;
        border-bottom: 3px solid #0000;

        &:hover,
        &.header--active {
            color: #ba181d;
            border-bottom: 3px solid #ba181d;
            position: relative;
        }
    }

    box-shadow: 0 0 6rem -1rem #0004;

    @media (max-width: 855px) {
        row-gap: 1rem;

        a:not(.logo) {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 738px) {
        flex-direction: column;
        align-items: flex-start;

        a.logo {
            position: absolute;
            right: 0;
            top: 0;

            img {
                width: 120px;
            }
        }
    }
}




.background-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    object-fit: cover;
    object-position: top;
    filter: saturate(0.7) blur(3);
    opacity: 0.5
}

main {
    max-width: 790px;
    margin: auto;
    padding: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: sans-serif;
    font-weight: 700;
    text-shadow: 1px 1px 1px #000;
}

main.fechas {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    article {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    h2 {
        width: 100%;
        margin: 0;
    }


    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
    }

    time {
        font-weight: lighter;
        font-family: Arial, Helvetica, sans-serif;
    }

    h3 {
        width: 100%;
        font-weight: lighter;
        margin: 0;
    }
}

p {
    font-family: sans-serif;
    font-weight: 400;
    text-shadow: 0.5px 0.5px 0.5px #000;
}

a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000000;
    font-family: sans-serif;

    &:hover {
        color: white;
        text-decoration: underline;
    }
}

ul.links {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0;

    li {

        margin: 0;
        padding: 0;

        a {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            color: #ffffff;
            text-decoration: none;
            font-weight: bold;
            text-shadow: 1px 1px 1px #000000;
            font-family: sans-serif;

            &:hover {
                color: white;
                text-decoration: underline;
            }
        }
    }
}

#logoFeteas {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    img {
        width: 80px;
    }
    @media (max-width: 425px) {
        left: 10px;
        right: auto;
        bottom: 170px
    }
}

#logo_escena_amateur {
    position: absolute;
    bottom: 10px;
    right: 100px;
    img {
        width: 80px;
    }
    cursor: pointer;
    @media (max-width: 425px) {
        left: 10px;
        right: auto;
        bottom: 120px
    }
}

.cartelera {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* Adjust 300px to the minimum width you want each grid item to be */
    gap: 1rem;
    /* CSS grid uses 'gap' instead of 'column-gap' */
    margin: 20px 0;
}


.cartel {
    max-width: 390px;
    position: relative;
    display: block;

    img {
        width: 100%;
    }
}

#masonry {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    overflow: hidden;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    row-gap: 0.5rem;

    img {
        width: 100%;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 0 1rem .2rem #000;

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 0 2rem .4rem #000;
        }
    }

    margin: 100px 1rem 100px;
}

.lista,
article {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 20px 50px;
    list-style: none;
    background-color: #0007;

    strong {
        font-size: 1.1rem;
        font-weight: 700;
        text-shadow: .5px .5px .5px #000;
        font-family: sans-serif;
    }
}

footer {
    display: flex;
    column-gap: 2rem;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 200px;
    a {
        display: flex;
        align-items: center;
    }
    div {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        column-gap: 1rem;
    }
    @media (max-width: 738px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        row-gap: 0.5rem;
        div {
            margin-bottom: 0.5rem;
        }
    }
    @media (max-width: 425px) {
        left: 10px;
        right: 10px;
    }
}